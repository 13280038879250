<template>
  <div class="home">
    <router-link tag="div" to="/courseIndex" class="homebg">
      <img class="img" src="../../image/first/study.jpg" />
        <div class="bgPo">
          <span>学习专区</span>
          <img class="play" src="../../image/first/play.png" />
        </div>
    </router-link>

    <div class="content">
      <div tag="div" to="/pingTuan" class="home-list">

        <router-link tag="div" to="/classSelect" class="item">
          <img src="../../image/first/home-1.png" />
          <p>题海</p>
        </router-link>

        <router-link tag="div" to="/wrongList" class="item">
          <img src="../../image/first/home-2.png" />
          <p>错题集</p>
        </router-link>

        <router-link tag="div" to="/askAnswer" class="item">
          <img src="../../image/first/home-3.png" />
          <p>问答</p>
        </router-link>

        <router-link tag="div" to="/uploadWork" class="item">
          <img src="../../image/first/home-4.png" />
          <p>上传作业</p>
        </router-link>

      </div>

      <div class="home-list">
        <router-link tag="div" to="/message" class="item">
          <img src="../../image/first/home-5.png" />
          <p>消息</p>
        </router-link>

        <router-link tag="div" to="/oneHundred" class="item">
          <img src="../../image/first/home-6.png" />
          <p>每日一练</p>
        </router-link>

        <router-link tag="div" to="/jionWork" class="item">
          <img src="../../image/first/home-7.png" />
          <p>合作加盟</p>
        </router-link>

        <router-link tag="div" to="/pingTuan" class="item">
          <img src="../../image/first/home-8.png" />
          <p>我的拼团</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate, baseUrl } from '../../common/util';

export default {
  name: 'Home',
  components: { },
  filters: {
    capitalize: function (value) {
      if (!value) return 0
      return value.toFixed(2)
    }
  },
  data () {
      return {
        // msg: '123456'
      }
  },
  beforeMount() {
      document.title = '首页';
  },
  mounted() {

  },
  methods: {
    
  }

}
</script>

<style lang="less" scoped>
  .home {
    // min-height: 100vh;
    padding-bottom: 3rem;
    box-sizing: border-box;
    .homebg {
      width: 100%;
      height: 3.5rem;
      position: relative;
      .img {
        display: block;
         width: 100%;
        height: 3.5rem;
      }
      .bgPo {
        position: absolute;
        top: 56%;
        left: 1.8rem;
        transform: translateY(-50%);
        font-size: 0.4rem;
        font-weight: bold;
        color: white;
        display: flex;
        align-items: center;
        .play {
          width: 0.8rem;
          height: 0.8rem;
          margin-left: 0.3rem;
        }
      }
    }
    .content {
      padding: 0 0.3rem 0.2rem;
      box-sizing: border-box;
      .home-list {
        margin-top: 0.5rem;
        display: flex;
        justify-content: space-between;
        .item {
          width: 1.4rem;
          text-align: center;
          cursor: pointer;
          img {
            margin: 0 auto;
            display: block;
            width: 1.2rem;
            height: 1.2rem;
            border-radius: 1.2rem;
            margin-bottom: 0.1rem;
          }
          p {
            font-size: 0.25rem;
          }
        }
      }
    }

  }
</style>
